<template>
  <section class="product-cust--standard">
    <div>
      <!-- <h2>Customizations</h2>
      <p
        class="product-cust--subheader"
      >Please select an option for each customization available for this product. None of these options will impact pricing and you must select a response for each customization.</p>-->
      <p class="text__bold text__md">Customizations</p>
      <p>None of these options will impact pricing and you must select a response for each customization.</p>
      <p class="form-error--message" v-if="!isValidated">
        You must provide a valid selection for
        <i>every</i> customization
      </p>
      <div
        v-if="product.customizations.length <= 0"
        class="product-cust--options__default flex__center"
      >
        <p class="text__bold text__center">This product has no customizations</p>
      </div>
      <div
        v-if="product.customizations.length > 0 && mountComplete && canEdit"
        class="product-cust--options p-tb-sm"
      >
        <SingleCust
          @sendvalue="setValue"
          v-for="(cust, index) in product.customizations"
          :key="cust._id"
          :index="index"
          :cust="cust"
          :selected="getCust(index)"
        />
      </div>
      <div
        v-if="product.customizations.length > 0 && mountComplete && !canEdit"
        class="product-cust--options p-tb-sm"
      >
        <p v-for="(cust, index) in product.customizations" :key="cust._id" class="flex__between">
          <span class="text__bold">{{ cust.name }}</span>
          <span class="ml-10">{{ getCust(index).option }}</span>
        </p>
      </div>
    </div>
    <!-- <div class="product-cust--submit"> -->
    <!-- <h2 class="text__white">Confirm</h2> -->
    <div class="product-cust--final">
      <p
        v-if="getNotes && getNotes.productNotes"
        class="pre-wrap text__bold"
      >{{ getNotes.productNotes }}</p>
      <div class="flex__between">
        <label>
          <span v-if="!canEdit" class="text__right">{{ form.quantity }}</span>
          <select
            v-if="canEdit"
            v-model="form.quantity"
            :disabled="orderItem && orderItem.status === 'Completed'"
          >
            <option v-for="num in 50" :value="num" :key="num">Qty {{ num }}</option>
          </select>
        </label>
        <!-- <p class="flex__col text__center">
        <span class="mr-10 text__md">Total Cost:</span>
        <span class="text__bold text__lg">${{ (form.quantity * form.loCost).toFixed(2) }}</span>
        </p>-->
        <button v-if="showAddButton" class="btn btn__frost text__md" @click="submit">Add to Order</button>
        <button
          v-if="canEdit && isOrderItem"
          :disabled="orderItem && orderItem.status === 'Completed'"
          class="btn btn__green text__md"
          @click="update"
        >Save Changes</button>
        <router-link
          v-if="showNewOrderButton"
          to="/new-order/order-details"
          class="btn btn__green text__md"
        >New Order</router-link>
      </div>
    </div>
    <Upsell v-if="showUpsell" :link="actionLink" :product="pairedProduct" :order="order" />
    <!-- <div v-if="!isOrderItem" class="flex__left">
      <router-link :to="productsLink" class="flex__left link__none">
        <font-awesome-icon icon="arrow-left" size="1x" class="mr-10" />Back to Products
      </router-link>
    </div>
    <div v-else class="flex__right">
      <router-link :to="orderConfirmLink" class="flex__left link__none">
        Back to Confirm
        <font-awesome-icon icon="arrow-right" size="1x" class="ml-10" />
      </router-link>
    </div>-->
    <!-- <div class="product-cust--btns"> -->
    <!-- <router-link v-if="!isOrderItem" :to="productsLink" class="flex__left link__none">
        <font-awesome-icon icon="arrow-left" size="1x" class="mr-10" />Back to Products
      </router-link>
      <router-link v-else :to="orderConfirmLink" class="flex__left link__none">
        Back to Confirm
        <font-awesome-icon icon="arrow-right" size="1x" class="ml-10" />
    </router-link>-->
    <!-- <button v-if="showAddButton" class="btn btn__frost text__md" @click="submit">Add to Order</button>
      <button
        v-if="canEdit && isOrderItem"
        :disabled="orderItem && orderItem.status === 'Completed'"
        class="btn btn__green m-tb-sm text__md"
        @click="update"
      >Save Changes</button>
      <router-link
        v-if="showNewOrderButton"
        to="/new-order/order-details"
        class="btn btn__green m-tb-sm text__md"
    >New Order</router-link>-->
    <!-- </div> -->
    <!-- </div> -->
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SingleCust from "./SingleCust";
import Upsell from "./Upsell";
export default {
  props: ["product", "order", "orderItem"],
  components: {
    SingleCust,
    Upsell
  },
  data() {
    return {
      mountComplete: false,
      showUpsell: false,
      isValidated: true,
      customizationCount: this.product ? this.product.customizations.length : 0,
      access: ["admin", "super-admin"],
      form: {
        order: this.order ? this.order._id : null,
        product: this.product ? this.product._id : null,
        productName: this.product ? this.product.name : null,
        isInventoried: this.product ? this.product.isInventoried : false,
        loCost: this.product ? this.product.loCost : null,
        itemCost:
          this.product && this.product.itemCost ? this.product.itemCost : 0,
        invoiceCost:
          this.product && this.product.invoiceCost
            ? this.product.invoiceCost
            : 0,
        quantity: 1,
        customizations: []
      }
    };
  },
  computed: {
    ...mapGetters(["getNotes", "getUserDetails", "getLiveProducts"]),
    pairedProduct() {
      if (this.product.paired) {
        const found = this.getLiveProducts.filter(
          item => item._id === this.product.paired
        );
        if (found.length > 0) return found[0];
      }
      return null;
    },
    route() {
      return this.$route.fullPath;
    },
    actionLink() {
      if (this.route.includes("/new-order")) {
        return `/new-order/${this.order._id}/products`;
      }
      return `/past-order/${this.order._id}/order-confirm`;
    },
    canEdit() {
      return (
        this.access.includes(this.getUserDetails.permission) ||
        this.order.status === "Started"
      );
    },
    isOrderItem() {
      return this.$route.query.item;
    },
    showAddButton() {
      return (
        (this.route.includes("/new-order") ||
          this.route.includes("/past-order")) &&
        !this.$route.query.item
      );
    },
    showNewOrderButton() {
      return (
        !this.route.includes("/new-order") &&
        !this.route.includes("/past-order")
      );
    },
    orderConfirmLink() {
      return this.route.includes("/past-order")
        ? `/past-order/${this.order._id}/order-confirm`
        : `/new-order/${this.order._id}/order-confirm`;
    },
    productsLink() {
      if (this.route.includes("/new-order")) {
        return `/new-order/${this.order._id}/products`;
      } else if (this.route.includes("/past-order")) {
        return `/past-order/${this.order._id}/products`;
      } else {
        return "/products";
      }
    }
  },
  methods: {
    ...mapActions([
      "startSetIsSubmitting",
      "startCreateOrderItem",
      "startUpdateOrderItem"
    ]),
    async submit() {
      this.validateForm();
      if (!this.isValidated) return;
      this.startSetIsSubmitting({ bool: true, heading: "Adding" });
      try {
        await this.startCreateOrderItem(this.form);
        this.startSetIsSubmitting({ bool: false });
        // trigger paired product if exists
        if (this.product.paired) {
          return (this.showUpsell = true);
        }
        this.$router.push(this.actionLink);
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    async update() {
      this.validateForm();
      if (!this.isValidated) return;
      this.startSetIsSubmitting({ bool: true, heading: "Updating" });
      try {
        const updateForm = { ...this.form, _id: this.$route.query.item };
        await this.startUpdateOrderItem(updateForm);
        this.startSetIsSubmitting({ bool: false });
        this.$router.push(this.orderConfirmLink);
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    validateForm() {
      this.isValidated = true;
      this.isValidated = this.form.quantity > 0 && this.isValidated;
      this.isValidated = this.form.quantity !== "" && this.isValidated;
      for (let i = 0; i < this.customizationCount; i += 1) {
        this.isValidated =
          this.form.customizations[i] !== "" &&
          typeof this.form.customizations[i] !== "undefined" &&
          this.isValidated;
      }
    },
    setValue(obj) {
      const index = this.form.customizations.findIndex(
        item => item.name === obj.name
      );
      const cust = { name: obj.name, option: obj.option };
      if (index >= 0) {
        this.form.customizations.splice(index, 1, cust);
      } else {
        this.form.customizations.push(cust);
      }
    },
    getCust(index) {
      if (this.$route.query.item && this.form.customizations) {
        return this.form.customizations[index];
      }
      return null;
    }
  },
  mounted() {
    if (this.$route.query.item) {
      const orderItem = this.order.orderItems.filter(item => {
        return item._id === this.$route.query.item;
      })[0];
      if (orderItem.customizations && orderItem.customizations.length > 0) {
        this.form.customizations = orderItem.customizations;
      }
      this.form.quantity = orderItem.quantity;
    }
    this.mountComplete = true;
  }
};
</script>

<style scoped lang="scss">
.product-cust--standard {
  @include grid($cols: 1fr, $row-gap: 25px, $col-gap: 25px);
}
.product-cust--options__default {
  background-color: $frost;
  height: 100px;
}
.product-cust--options {
  @include grid($cols: 1fr, $col-gap: 15px, $row-gap: 15px);
}
label {
  font-weight: bold;
}
.product-cust--standard label {
  @include grid($cols: 1fr, $col-gap: 2%, $row-gap: 3px, $align: center);
}
.product-cust--submit {
  background-color: $peacock;
  padding: 20px;
}
/* .product-cust--final {
  @include grid($cols: 1fr, $row-gap: 15px);
  label {
    @include grid($cols: 1fr 3fr, $col-gap: 25px, $align: center);
  }
} */
.product-cust--btns {
  @include flex($dir: column-reverse, $just: space-between, $align: center);
}
button:disabled {
  background-color: $cloud;
  color: #aaa;
}
.product-cust--final {
  @include grid($cols: 1fr, $row-gap: 20px);
}
/* @media (min-width: 425px) {
  .product-cust--final {
    @include flex($just: space-between, $align: center);
  }
  .product-cust--btns {
    flex-direction: row;
  }
} */
/* @media (min-width: $sm) {
  .product-cust--options label {
    grid-template-columns: 35% 63%;
    align-items: flex-start;
  }
} */
@media (min-width: $md) {
  .product-cust--standard {
    grid-template-columns: 2fr 1fr;
    align-items: baseline;
    grid-column-gap: 50px;
  }
  .product-cust--submit {
    @include grid($cols: 1fr, $row-gap: 10px);
  }
  .product-cust--final,
  .product-cust--final .flex__between {
    @include grid($cols: 1fr, $row-gap: 25px);
  }
  .product-cust--btns {
    @include grid($cols: 1fr, $row-gap: 10px);
    a {
      margin: auto;
    }
  }
}
@media (min-width: $lg) {
  .product-cust--standard {
    grid-column-gap: 75px;
  }
}
/* @media (min-width: $xl) {
  .product-cust--options {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    label {
      grid-template-columns: 1fr;
    }
  }
} */
</style>