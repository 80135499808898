<template>
  <section class="modal flex__center">
    <div class="tile">
      <h3 class="text__center">Add Product</h3>
      <div class="product-show">
        <img :src="product.images[0]" :alt="`image for ${product.name}`" width="100%" height="100%" />
        <p class="text__bold">{{ product.name }}</p>
      </div>
      <p>This product pairs perfectly, add it to your order?</p>
      <div class="flex__between mt-15">
        <router-link :to="link" class="btn btn__sm btn__red">No, Thanks</router-link>
        <router-link :to="addRoute" class="btn btn__sm btn__green">Add It</router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["link", "product", "order"],
  computed: {
    ...mapGetters(["getProducts"]),
    addRoute() {
      if (this.$route.fullPath.includes("new-order")) {
        return `/new-order/${this.order._id}/products/${this.product._id}`;
      }
      return `/past-order/${this.order._id}/products/${this.product._id}`;
    }
  }
};
</script>

<style scoped lang="scss">
.tile {
  @include grid($cols: 1fr, $row-gap: 10px);
  border-radius: 5px;
}
.product-show {
  @include grid($cols: 100px 1fr, $col-gap: 10px, $align: center);
}
</style>