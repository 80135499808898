<template>
  <div class="product-cust--admin">
    <h2>Customizations</h2>
    <p class="mb-15">
      You may create or add new customizations below as well as edit existing customizations for this product.
      <i>Quantity should not be added as it is created by default for all products.</i>
    </p>
    <div class="product-cust--admin__grid">
      <EditNew :product="product" />
      <EditExisting
        v-for="cust in product.customizations"
        :key="cust._id"
        :product="product"
        :cust="cust"
      />
    </div>
  </div>
</template>

<script>
import EditNew from "./EditNew";
import EditExisting from "./EditExisting";
export default {
  props: ["product"],
  components: {
    EditNew,
    EditExisting
  }
};
</script>

<style scoped lang="scss">
.product-cust--admin__grid {
  @include grid($cols: 1fr, $row-gap: 15px, $col-gap: 15px);
}
@media (min-width: $sm) {
  .product-cust--admin__grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: $lg) {
  .product-cust--admin__grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>