<template>
  <div class="product-cust--edit tile-mini">
    <h3>Existing</h3>
    <div class="flex__between">
      <button class="btn btn__sm btn__frost" @click="toggleDeleting">Delete</button>
      <button class="btn btn__sm btn__red" @click="toggleEditing">{{ editing ? "Cancel" : "Edit" }}</button>
    </div>
    <label :class="[errors.name ? 'form--error' : null]">
      Name
      <span v-if="!editing">{{ cust.name }}</span>
      <input v-else type="text" v-model="form.customizations.name" placeholder="name" />
    </label>
    <form-error v-if="editing" :message="errors.name" />
    <label>
      Type*
      <span v-if="!editing">{{ cust.type }}</span>
      <select v-else v-model="form.customizations.type">
        <option value="text">Text</option>
        <option value="select">Select</option>
        <option value="textarea">Long Text</option>
      </select>
    </label>
    <label
      v-if="form.customizations.type === 'select'"
      :class="[errors.options ? 'form--error' : null]"
    >
      Options*
      <span v-if="!editing">{{ cust.options.join(", ") }}</span>
      <input v-else type="text" v-model="options" />
    </label>
    <form-error v-if="editing" :message="errors.options" />
    <label
      v-if="form.customizations.type === 'textarea'"
      :class="[errors.charLimit ? 'form--error' : null]"
    >
      Character Limit*
      <span v-if="!editing">{{ cust.charLimit }}</span>
      <input v-else type="number" min="1" max="1000" v-model="form.customizations.charLimit" />
    </label>
    <form-error v-if="editing" :message="errors.charLimit" />
    <div v-if="editing" class="flex__between">
      <button class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
      <button class="btn btn__sm btn__green" @click="submit">Done</button>
    </div>
    <delete v-if="deleting">
      <DeleteCust @toggleDeleting="toggleDeleting" :product="product" :cust="cust" />
    </delete>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DeleteCust from "./DeleteCust";
export default {
  props: ["product", "cust"],
  components: {
    DeleteCust
  },
  data() {
    return {
      editing: false,
      deleting: false,
      isValidated: true,
      form: {
        _id: this.product ? this.product._id : null,
        customizations: {
          _id: this.cust ? this.cust._id : null,
          name: this.cust.name ? this.cust.name : "",
          type: this.cust.type ? this.cust.type : "text",
          options: null,
          charLimit: this.cust.charLimit ? this.cust.charLimit : 0
        }
      },
      options: this.cust.options ? this.cust.options.join(", ") : null,
      errors: {
        name: null,
        options: null,
        charLimit: null
      }
    };
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateProductCust"]),
    async submit() {
      this.validateForm();
      if (this.isValidated) {
        this.startSetIsSubmitting({ bool: true, heading: "Updating" });
        try {
          this.form.customizations.options = this.options
            ? this.options
                .toLowerCase()
                .split(",")
                .map(item => item.trim())
            : null;
          await this.startUpdateProductCust(this.form);
          this.startSetIsSubmitting({ bool: false });
          this.toggleEditing();
        } catch (err) {
          this.startSetIsSubmitting({ bool: false });
        }
      }
    },
    validateForm() {
      this.isValidated = true;
      this.errors.name =
        this.form.customizations.name === "" ? "Please enter a name" : null;
      if (this.form.customizations.type === "select") {
        this.errors.options =
          this.options === "" ? "Please enter at least one option" : null;
      } else {
        this.errors.options = null;
      }
      if (this.form.customizations.type === "textarea") {
        this.errors.charLimit =
          this.form.customizations.charLimit <= 0 ||
          this.form.customizations.charLimit > 1000
            ? "Please enter a number between 1 and 1000"
            : null;
      } else {
        this.errors.charLimit = null;
      }
      for (let key in this.errors) {
        if (this.errors[key] === null) {
          this.isValidated = this.isValidated && true;
        } else {
          this.isValidated = this.isValidated && false;
        }
      }
    },
    toggleEditing() {
      this.editing = !this.editing;
    },
    toggleDeleting() {
      this.deleting = !this.deleting;
    }
  }
};
</script>

<style scoped lang="scss">
.product-cust--edit {
  label {
    font-weight: bold;
    @include grid(
      $cols: 1.25fr 2fr,
      $row-gap: 3px,
      $col-gap: 5px,
      $align: center
    );
    span {
      font-weight: normal;
      text-align: right;
    }
  }
}
@media (min-width: $sm) {
  .product-cust--edit label {
    grid-template-columns: 1fr;
    span {
      text-align: left;
    }
  }
}
@media (min-width: $md) {
  .product-cust--edit label {
    grid-template-columns: 1fr 2fr;
    span {
      text-align: right;
    }
  }
}
</style>