<template>
  <div>
    <p class="text__lg text__center text__bold text__white">Delete Customization: {{ cust.name }}</p>
    <p
      class="text__white text__center p-tb-sm"
    >Deleting this customization cannot be undone, it will no longer be available as a customization for this product on this site in any way.</p>
    <p class="text__md text__center text__bold text__white">Proceed?</p>
    <div class="flex__between">
      <button class="btn btn__sm btn__frost" @click="emitToggleDeleting">No, Go Back</button>
      <button class="btn btn__sm btn__green" @click="deleteCustomization">Yes, Delete</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["canEdit", "product", "cust"],
  data() {
    return {
      form: {
        _id: this.product ? this.product._id : null,
        customizations: {
          _id: this.cust ? this.cust._id : null,
          name: this.cust ? this.cust.name : null
        }
      }
    };
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startDeleteProductCust"]),
    emitToggleDeleting() {
      this.$emit("toggleDeleting", false);
    },
    async deleteCustomization() {
      this.startSetIsSubmitting({ bool: true, heading: "Deleting" });
      try {
        await this.startDeleteProductCust(this.form);
        this.startSetIsSubmitting({ bool: false });
        this.emitToggleDeleting();
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    }
  }
};
</script>

<style scoped lang="scss">
</style>