<template>
  <div class="product-cust--new tile-mini">
    <div class="product-cust--new__form">
      <label :class="[errors.name ? 'form--error' : null]">
        Customization Name*
        <input
          type="text"
          v-model="form.customizations.name"
          placeholder="Color"
        />
      </label>
      <form-error :message="errors.name" />
      <label>
        Customization Type
        <select v-model="form.customizations.type">
          <option value="text">Text</option>
          <option value="select">Select</option>
          <option value="textarea">Long Text</option>
        </select>
      </label>
      <label
        v-if="form.customizations.type === 'select'"
        :class="[errors.options ? 'form--error' : null]"
      >
        Options*
        <span class="text__sm text__light">comma separated</span>
        <input
          type="text"
          v-model="options"
          placeholder="option 1, option 2, ..."
          class="span__two"
        />
      </label>
      <form-error v-if="form.customizations.type === 'select'" :message="errors.options" />
      <label
        v-if="form.customizations.type === 'textarea'"
        :class="[errors.charLimit ? 'form--error' : null]"
      >
        Character Limit*
        <input
          type="number"
          v-model="form.customizations.charLimit"
          min="1"
          max="1000"
        />
      </label>
      <form-error v-if="form.customizations.type === 'textarea'" :message="errors.charLimit" />
    </div>
    <div class="flex__center">
      <button class="btn btn__sm btn__green" @click="submit">Submit New</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["product"],
  data() {
    return {
      isValidated: true,
      form: {
        _id: this.product ? this.product._id : null,
        customizations: {
          name: "",
          type: "text",
          options: null,
          charLimit: 0
        }
      },
      options: "",
      errors: {
        name: null,
        options: null,
        charLimit: null
      }
    };
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startCreateProductCust"]),
    async submit() {
      this.validateForm();
      if (this.isValidated) {
        this.startSetIsSubmitting({ bool: true, heading: "Adding" });
        try {
          this.form.customizations.options = this.options
            ? this.options
                .toLowerCase()
                .split(",")
                .map(item => item.trim())
            : null;
          await this.startCreateProductCust(this.form);
          this.clearForm();
          this.startSetIsSubmitting({ bool: false });
        } catch (err) {
          this.startSetIsSubmitting({ bool: false });
        }
      }
    },
    validateForm() {
      this.isValidated = true;
      this.errors.name =
        this.form.customizations.name === "" ? "Please enter a name" : null;
      if (this.form.customizations.type === "select") {
        this.errors.options =
          this.options === "" ? "Please enter at least one option" : null;
      } else {
        this.errors.options = null;
      }
      if (this.form.customizations.type === "textarea") {
        this.errors.charLimit =
          this.form.customizations.charLimit <= 0 ||
          this.form.customizations.charLimit > 1000
            ? "Please enter a number between 1 and 1000"
            : null;
      } else {
        this.errors.charLimit = null;
      }
      for (let key in this.errors) {
        if (this.errors[key] === null) {
          this.isValidated = this.isValidated && true;
        } else {
          this.isValidated = this.isValidated && false;
        }
      }
    },
    clearForm() {
      this.form.customizations.name = "";
      this.form.customizations.type = "text";
      this.options = "";
      this.form.customizations.options = null;
      this.form.customizations.charLimit = 0;
    }
  }
};
</script>

<style scoped lang="scss">
.product-cust--new__form {
  @include grid($cols: 1fr, $row-gap: 15px);
  label {
    font-weight: bold;
    @include grid($cols: 1fr, $row-gap: 3px, $col-gap: 15px, $align: center);
  }
}
@media (min-width: $md) {
  .product-cust--new__form label {
    grid-template-columns: 125px calc(100% - 140px);
  }
}
@media (min-width: $lg) {
  .product-cust--new__form label {
    grid-template-columns: 1fr;
  }
}
</style>