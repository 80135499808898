<template>
  <section class="product-cust margin__sm">
    <Standard v-if="!showEditing" :product="product" :order="order" :orderItem="orderItem" />
    <Editing v-if="showEditing && canEdit" :product="product" />
  </section>
</template>

<script>
import Standard from "./Standard";
import Editing from "./Editing";
export default {
  props: ["canEdit", "product", "order", "orderItem"],
  components: {
    Standard,
    Editing
  },
  computed: {
    route() {
      return this.$route.fullPath;
    },
    showEditing() {
      return this.route.includes("/admin");
    }
  }
};
</script>

<style scoped lang="scss">
.product-cust {
  @include grid($cols: 1fr, $row-gap: 25px);
}
</style>